<script>
	import axios from 'axios'
	export default {
		data() {
			return {
				items:{},
				selected:undefined,
			}
		},
		async mounted(){
			//this.switch(this.Models[this.SelectedType][0])
			axios.get("https://icontrol.digitalblend.fr/api/dewitte/company/all")
				.then(response => {
					this.items = response.data
				})
		},
	}
</script>
<template>
	<div class="mainDiv">
		<div class="top"> 
			<h1>The Company</h1>
			<div v-for="item in items">
					<p v-html="item.subTitle"></p>
				</div>
			<!-- <p>When requirement is pronounced with a South-West accent</p> -->
		</div>
		<div class="content">
			<div class="text">
				<img class="image" src="../../public/assets/nicolas-dewitte.webp">
				<div v-for="item in items">
					<p v-html="item.description"></p>
				</div>
			</div>
		</div>
		<div class="video">
			<iframe width="100%;" height="600px;" src="https://www.youtube.com/embed/ZT2sRC-8wuc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
	</div>
</template>

<style scoped>
	

	.top{
		border-top: 2px solid black;
		border-bottom: 2px solid black;
		text-align: center;
		margin-left: 30%;
		margin-right: 30%;
		margin-bottom: 25px;
	}

	.content{
		display: flex;
		flex-flow: row wrap;
	}

	.image{
		float: left;
		margin: 20px;
		margin-top: 20px;
		width: auto;
		min-height: 400px;
	}

	/* THIS IS FOR PHONE */
	@media (max-width: 500px) {
		.text{
			width: calc(100vw - 45px);
		}

		.mainDiv{
			margin: 5px;
		}

		.video{
			width: calc(100vw - 10px);
		}

		.image{
			width: calc(100vw - 10px);
			height: 524px;
		}
	}

	/* THIS IS FOR PC */
	@media (min-width: 500px) {

		.text{
			width: calc(100vw - 45px);
		}
		.video{
			margin: 5px 100px;
			width: calc(100vw - 230px);
		}

		.mainDiv{
			margin: 15px;
		}
	}

	.text{
		margin-left: 15px;
		text-align: left;
		
	}

	.video{
		height: 600px;
	}
</style>
